.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #080c0d;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */

.hamburger-menu {
  div {
    width: 1.5rem;
    height: 0.2rem;
    background: #d9310d;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
  }
}
.contact-info {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 20px;
}

@media (min-width: 900px) {
  .contact-info {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 900px) {
  .contact-info {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 500px) {
  .contact-info {
    grid-template-columns: repeat(1, 1fr);
  }
}

.aboutUs {
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.inquiryForm {
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}
.about-us-title {
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 20px;
  color: #2c3e50;
}

.about-us-intro {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 30px;
}

.about-us-subtitle {
  font-size: 2em;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #d9310d;
}

.about-us-text {
  margin-bottom: 20px;
  text-align: justify;
}

.about-us-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

.about-us-list li {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.about-us-closing {
  font-size: 1.2em;
  text-align: center;
  margin-top: 20px;
  color: #27ae60;
}
